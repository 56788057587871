import React from 'react'
import { Grid, Typography } from '@mui/material'
import {
  Button,
  SimpleForm,
  required,
  useCreate,
  useRecordContext,
} from 'react-admin'
import CheckCircleIcon from '@mui/icons-material/CheckCircleOutlined'
import SaveIcon from '@mui/icons-material/SaveOutlined'
import { QuoteOrderDetailsToolbarProps } from './QuoteOrderDetailsToolbar.types'
import { MarkdownInput } from '@react-admin/ra-markdown'
import { NotesDialogToolbar } from '../../atoms/NotesDialogToolbar'
import { CreateInDialogButton } from '@react-admin/ra-form-layout'

export const QuoteOrderDetailsToolbar: React.FC<
  QuoteOrderDetailsToolbarProps
> = (props) => {
  const record = useRecordContext()
  const [create] = useCreate()

  const submitForm = async (data: object) => {
    await create('notes', { data })
    props.onConfirm()
  }

  return !record?.isOrderConfirmed ? (
    <Grid container spacing={1} justifyContent="flex-end">
      <Grid item>
        <Button
          size="small"
          label="Save"
          type="submit"
          variant="outlined"
          color="info"
          startIcon={<SaveIcon />}
        />
      </Grid>
      <Grid item>
        <CreateInDialogButton
          title={<Typography variant="h6">Add Note</Typography>}
          ButtonProps={{
            size: 'small',
            type: 'button',
            variant: 'contained',
            color: 'info',
          }}
          label="Confirm"
          icon={<CheckCircleIcon />}
          fullWidth
          maxWidth="md"
        >
          <SimpleForm
            defaultValues={{
              isInternal: true,
              relatedName: 'RequestProduct',
              relatedId: props.requestProductId,
              clientId: props.clientId,
            }}
            toolbar={<NotesDialogToolbar />}
            sx={{ paddingY: 0 }}
            onSubmit={submitForm}
          >
            <MarkdownInput
              height="200px"
              fullWidth
              label={false}
              source="content"
              validate={required()}
              placeholder={'Add a note here.'}
            />
          </SimpleForm>
        </CreateInDialogButton>
      </Grid>
    </Grid>
  ) : null
}
