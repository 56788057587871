import React from 'react'
import { List, ListProps } from 'react-admin'
import { RequestServiceFilters } from '../../molecules/RequestServiceFilters'
import { RequestServiceDatagrid } from '../../molecules/RequestServiceDatagrid'

/** Page to display a list of request services. */
export const RequestServiceList: React.FC<Partial<ListProps>> = (props) => {
  const defaultFilter = {
    channel: ['portal'],
    state: [
      'pending-request',
      'pending-review',
      'pending-offers',
      'pending-order',
      'pending-order-confirmation',
      'ordered',
    ],
  }

  return (
    <List
      empty={false}
      filters={<RequestServiceFilters />}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      perPage={25}
      filterDefaultValues={defaultFilter}
      {...props}
    >
      <RequestServiceDatagrid />
    </List>
  )
}
